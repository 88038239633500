import { memo } from "react"

interface QuoteProps {
  text: string
  avatar: string
  name: string
  className: string
}

export const Quote: React.FC<QuoteProps> = memo((props: QuoteProps) => {
  return (
    <div className={`flex flex-col sm:ml-14 ${props.className}`}>
      <div className="text-lg italic text-zinc-200">
        &quot;{props.text}&quot;
      </div>
      <div className="mt-auto flex justify-end">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.producthunt.com/posts/frontender"
          className="mt-4 inline-flex items-center justify-end"
        >
          <span className="mr-4 block truncate font-medium text-zinc-200">
            {props.name}
          </span>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            src={props.avatar}
            alt={props.name}
            width={36}
            className="rounded-full"
          />
        </a>
      </div>
    </div>
  )
})

Quote.displayName = "Quote"
