import { ReactNode, memo } from "react"

interface ButtonProps {
  children: ReactNode
  disabled?: boolean
  type?: "submit"
  onClick?: () => void
}

export const Button: React.FC<ButtonProps> = memo((props: ButtonProps) => {
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={props.type || "button"}
      onClick={props.onClick}
      disabled={props.disabled}
      className="w-full rounded-lg bg-[#0c8ce9] px-4 py-2 text-sm font-semibold text-white hover:opacity-95 active:opacity-90 disabled:opacity-50"
    >
      {props.children}
    </button>
  )
})

Button.displayName = "Button"
