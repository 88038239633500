import { ReactNode, memo } from "react"

interface FeatureProps {
  title: string
  src: string
  children: ReactNode
  end?: boolean
  border?: boolean
  cap?: boolean
  icon: ReactNode
}

export const Feature: React.FC<FeatureProps> = memo((props: FeatureProps) => {
  return (
    <div
      className={`items-center border-t border-zinc-700 pr-6 md:border-t-0 xl:flex ${
        props.border ? "md:border-r" : ""
      }`}
    >
      <div className="pb-10 pl-14 pr-16 pt-14 xl:py-20 2xl:py-24 2xl:pl-16">
        {props.icon}
        <div
          className={`text-xl font-semibold text-zinc-200 ${
            props.cap ? "max-w-[260px]" : ""
          }`}
        >
          {props.title}
        </div>
        <p className="mt-2 max-w-sm leading-relaxed text-zinc-400">
          {props.children}
        </p>
      </div>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        src={props.src}
        alt=""
        className={`mx-auto ${
          props.end ? "self-end" : "mb-8 sm:-mt-4 sm:mb-0 xl:mr-3 xl:mt-3"
        } ${props.title.startsWith("Customiza") ? "mt-4" : ""}`}
      />
    </div>
  )
})

Feature.displayName = "Feature"
