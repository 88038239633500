/* eslint-disable react/jsx-no-bind */
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import Typewriter, { TypewriterClass } from "typewriter-effect"
import { Grammar, highlight, languages } from "prismjs"
// eslint-disable-next-line import/no-unassigned-import
import "prismjs/components/prism-jsx"
import { motion } from "framer-motion"
import Head from "next/head"
import { Header } from "../components/Header"
import { Footer } from "../components/Footer"
import { Button } from "../components/Button"
import { Feature } from "../components/Feature"
import { PageProps } from "../utils/types"
import { Quote } from "../components/Quote"

const headerVariants = {
  animate: {
    opacity: 1,
  },
  initial: {
    opacity: 0,
  },
}

const descVariants = {
  animate: {
    opacity: 1,
    transition: {
      delay: 0.07,
    },
  },
  initial: {
    opacity: 0,
  },
}

const featureVariants1 = {
  animate: {
    opacity: 1,
    transition: {
      delay: 0.26,
    },
  },
  initial: {
    opacity: 0,
  },
}

const featureVariants2 = {
  animate: {
    opacity: 1,
    transition: {
      delay: 0.36,
    },
  },
  initial: {
    opacity: 0,
  },
}

const figmaVariants = {
  animate: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0.15,
      duration: 0.3,
    },
    y: 0,
  },
  initial: {
    opacity: 0,
    y: -3,
  },
}

export default function Home(props: PageProps) {
  const headingRef = useRef<HTMLHeadingElement>(null)
  const [headerPos, setHeaderPos] = useState<{ top: number; left: number }>()
  const [video, setVideo] = useState(false)
  const [language, setLanguage] = useState("JSX with Tailwind")

  const codeSample = useMemo(() => {
    if (language === "Only CSS") {
      return `background-clip: text;
-webkit-background-clip: text;
background: linear-gradient(90deg, #7dd3fc, #d946ef);
color: transparent;
font-size: 60px;
font-weight: 800;
letter-spacing: -3px;
line-height: 1.1;
text-align: center;`
    }
    if (language === "Only CSS-in-JS") {
      return `backgroundClip: "text",
WebkitBackgroundClip: "text",
background: "linear-gradient(90deg, #7dd3fc, #d946ef)",
color: "transparent",
fontSize: 60,
fontWeight: 800,
letterSpacing: -3,
lineHeight: "1.1",
textAlign: "center"`
    }
    if (language === "Only Tailwind") {
      return "text-6xl font-extrabold leading-tight tracking-tighter text-center text-transparent bg-clip-text bg-gradient-to-r from-sky-300 to-fuchsia-500"
    }
    if (language === "HTML with CSS") {
      return `<h1 style="background-clip: text; -webkit-background-clip: text; background: linear-gradient(90deg, #7dd3fc, #d946ef); color: transparent; font-size: 60px; font-weight: 800; letter-spacing: -3px; line-height: 1.1; text-align: center">
  The Figma plugin that writes front-end code.
</h1>`
    }
    if (language === "HTML with Tailwind") {
      return `<h1 class="text-6xl font-extrabold leading-tight tracking-tighter text-center text-transparent bg-clip-text bg-gradient-to-r from-sky-300 to-fuchsia-500">
  The Figma plugin that writes front-end code.
</h1>`
    }
    if (language === "JSX with CSS-in-JS") {
      return `<h1 style={{
  backgroundClip: "text",
  WebkitBackgroundClip: "text",
  background: "linear-gradient(90deg, #7dd3fc, #d946ef)",
  color: "transparent",
  fontSize: 60,
  fontWeight: 800,
  letterSpacing: -3,
  lineHeight: "1.1",
  textAlign: "center"
}}>
  The Figma plugin that writes front-end code.
</h1>`
    }
    if (language === "JSX with Tailwind") {
      return `<h1 className="text-6xl font-extrabold leading-tight tracking-tighter text-center text-transparent bg-clip-text bg-gradient-to-r from-sky-300 to-fuchsia-500">
  The Figma plugin that writes front-end code.
</h1>`
    }
  }, [language])

  useEffect(() => {
    const headerRect = headingRef.current?.getBoundingClientRect()
    if (headerRect) {
      setHeaderPos({
        left: headerRect.left + 200,
        top: headerRect.bottom - 70,
      })
    }
  }, [])

  const cursorVariants = {
    animate: {
      left: headerPos?.left,
      top: headerPos?.top,
      transition: {
        delay: 2.5,
        duration: 1,
        ease: "easeInOut",
      },
    },
    initial: {
      left:
        typeof window !== "undefined"
          ? window.innerWidth > 500
            ? window.innerWidth / 3.5
            : 30
          : -40,
      top: typeof window !== "undefined" ? window.innerHeight / 2 : -40,
    },
  }

  const stringSplitter = useCallback((string: string) => {
    const str = string
      .split("&lt;")
      .join("<")
      .split("&gt;")
      .join(">")
      .split("&quot;")
      .join('"')
      .split("&apos;")
      .join("'")
      .split("&amp;")
      .join("&")
    // return str.split("")
    return str.match(/[\s\S]{1,2}/g) || []
  }, [])

  const [state, setState] = useState<"hover" | "clicked" | undefined>()

  const handleTypewriterInit = useCallback(
    (typewriter: TypewriterClass) => {
      const code = highlight(
        state === "clicked"
          ? codeSample || ""
          : "// Select something to write code",
        (state === "clicked"
          ? language.includes("JS")
            ? languages.jsx
            : language === "Only CSS"
            ? languages.css
            : languages.html
          : languages.javascript) as Grammar,
        state === "clicked"
          ? language.includes("JS")
            ? "jsx"
            : language === "Only CSS"
            ? "css"
            : "html"
          : "javascript"
      )
      // Highlight code and replace escaped characters.
      typewriter.pauseFor(600).typeString(code).start()
    },
    [codeSample, language, state]
  )

  useEffect(() => {
    if (typeof window !== "undefined" && window.innerHeight < 900) {
      setLimitedHeight(true)
    }
  }, [])

  const [limitedHeight, setLimitedHeight] = useState(false)

  return (
    <>
      <Head>
        <title>Frontender | Speed up your front-end workflow</title>
        <meta name="theme-color" content="#18181b" />
        <meta property="og:image" content="https://www.frontender.io/og.png" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
        <meta property="og:title" content="Frontender" />
        <meta
          property="description"
          content="Speed up your front-end workflow."
        />
        <meta
          property="og:description"
          content="Speed up your front-end workflow."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.frontender.io/" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="msapplication-TileColor" content="#da532c" />
      </Head>
      <motion.div
        initial="initial"
        animate={headerPos ? "animate" : "initial"}
        className="flex min-h-screen select-none bg-gradient-to-b from-zinc-900 via-zinc-900 to-[#111f2b] p-4"
      >
        {headerPos && (
          <motion.svg
            variants={cursorVariants}
            version="1.1"
            // eslint-disable-next-line react/jsx-no-bind
            onUpdate={(latest) => {
              if (
                ((latest.top as number) || 0) <=
                  ((headerPos?.top as number) || 0) + 70 &&
                !state
              ) {
                setState("hover")
              }
            }}
            // eslint-disable-next-line react/jsx-no-bind
            onAnimationComplete={(definition) => {
              if (definition === "animate") {
                setTimeout(() => setState("clicked"), 300)
              }
            }}
            className="pointer-events-none absolute z-20 w-9 shadow-sm"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 28 28"
            enableBackground="new 0 0 28 28"
            xmlSpace="preserve"
          >
            <polygon
              fill="#FFFFFF"
              points="8.2,20.9 8.2,4.9 19.8,16.5 13,16.5 12.6,16.6 "
            />
            <polygon
              fill="#FFFFFF"
              points="17.3,21.6 13.7,23.1 9,12 12.7,10.5 "
            />
            <rect
              x="12.5"
              y="13.6"
              transform="matrix(0.9221 -0.3871 0.3871 0.9221 -5.7605 6.5909)"
              width="2"
              height="8"
            />
            <polygon points="9.2,7.3 9.2,18.5 12.2,15.6 12.6,15.5 17.4,15.5 " />
          </motion.svg>
        )}
        {video && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-zinc-900/50 p-4 sm:p-24">
            <button
              type="button"
              className="absolute inset-0 bg-zinc-900/50"
              // eslint-disable-next-line react/jsx-no-bind
              onClick={() => setVideo(false)}
            />
            <div className="relative z-50 aspect-video w-full max-w-screen-lg rounded-lg bg-white p-1 sm:p-2.5">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube-nocookie.com/embed/3w5DYlqGn8w"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        )}
        <div className="flex flex-1 flex-col border-zinc-700 p-4 pt-1 sm:border sm:p-7 sm:pt-3">
          <Header />

          <div className="flex flex-1 flex-col p-0 sm:p-2 lg:px-8 lg:pt-6">
            <motion.h2
              ref={headingRef}
              variants={headerVariants}
              className={`
            relative mx-auto mb-4 mt-10 max-w-[650px] border-2 bg-gradient-to-r from-sky-300 to-fuchsia-500 bg-clip-text p-2 text-center text-4xl font-extrabold leading-[1.15] tracking-tight text-transparent sm:text-6xl sm:leading-[1.15] sm:tracking-tighter
            ${
              state === "hover"
                ? "underline decoration-blue-500 decoration-2 underline-offset-2"
                : " "
            }
            ${state === "clicked" ? "border-blue-500" : " border-transparent"}
          `}
            >
              {state === "clicked" && (
                <>
                  <span className="absolute -left-1.5 -top-1.5 h-3 w-3 border-2 border-blue-500 bg-white" />
                  <span className="absolute -right-1.5 -top-1.5 h-3 w-3 border-2 border-blue-500 bg-white" />
                  <span className="absolute -bottom-1.5 -left-1.5 h-3 w-3 border-2 border-blue-500 bg-white" />
                  <span className="absolute -bottom-1.5 -right-1.5 h-3 w-3 border-2 border-blue-500 bg-white" />
                </>
              )}
              The Figma plugin that writes front-end code.
            </motion.h2>
            <div className="animate-blob pointer-events-none absolute left-[20vw] top-[20vh] h-[60vh] w-[60vw] rounded-full bg-purple-500 opacity-10 blur-3xl" />
            <motion.p
              variants={descVariants}
              className="text-center text-lg leading-relaxed text-zinc-400 sm:text-xl sm:leading-relaxed"
            >
              Frontender is your personal junior developer. Try it today.
            </motion.p>
            <motion.div
              variants={descVariants}
              className="mx-auto mt-5 flex items-center space-x-3 text-sm text-zinc-400"
            >
              <div className="flex -space-x-1">
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img
                  src="/a1.jpeg"
                  alt=""
                  className="h-7 w-7 rounded-full border-2 border-indigo-500 bg-zinc-800 drop-shadow-sm"
                />
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img
                  src="/a3.jpeg"
                  alt=""
                  className="h-7 w-7 rounded-full border-2 border-fuchsia-500 bg-zinc-800 drop-shadow-sm"
                />
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img
                  src="/a2.jpeg"
                  alt=""
                  className="h-7 w-7 rounded-full border-2 border-amber-500 bg-zinc-800 drop-shadow-sm"
                />
              </div>
              <div>
                Trusted by 5.000+ developers
                <span className="hidden sm:inline"> in 30+ countries</span>!
              </div>
            </motion.div>
            <div
              className={`relative my-10 flex min-h-[440px] flex-1 justify-center overflow-hidden py-4 ${
                !limitedHeight ? "items-center" : ""
              }`}
            >
              <motion.div
                variants={figmaVariants}
                className="figma relative h-96 w-64 border border-zinc-700 bg-[#2c2c2c] shadow-2xl shadow-black/20"
              >
                <motion.div
                  variants={figmaVariants}
                  className="absolute -bottom-6 -left-8 rounded-xl bg-zinc-900 p-3"
                >
                  <svg
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 200 300"
                    className="h-11 w-11"
                  >
                    <path
                      id="path0_fill"
                      className="st0"
                      d="M50 300c27.6 0 50-22.4 50-50v-50H50c-27.6 0-50 22.4-50 50s22.4 50 50 50z"
                    />
                    <path
                      id="path1_fill"
                      className="st1"
                      d="M0 150c0-27.6 22.4-50 50-50h50v100H50c-27.6 0-50-22.4-50-50z"
                    />
                    <path
                      id="path1_fill_1_"
                      className="st2"
                      d="M0 50C0 22.4 22.4 0 50 0h50v100H50C22.4 100 0 77.6 0 50z"
                    />
                    <path
                      id="path2_fill"
                      className="st3"
                      d="M100 0h50c27.6 0 50 22.4 50 50s-22.4 50-50 50h-50V0z"
                    />
                    <path
                      id="path3_fill"
                      className="st4"
                      d="M200 150c0 27.6-22.4 50-50 50s-50-22.4-50-50 22.4-50 50-50 50 22.4 50 50z"
                    />
                  </svg>
                </motion.div>
                <div className="flex items-center border-b border-[#444] p-2.5">
                  <div className="mr-3 h-4 w-4 rounded-sm bg-gradient-to-br from-sky-300 to-fuchsia-500 p-0.5 text-white" />
                  <div className="text-xs font-semibold text-white">
                    Frontender
                  </div>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ml-auto text-zinc-400"
                  >
                    <path
                      d="M11.7816 4.03157C12.0062 3.80702 12.0062 3.44295 11.7816 3.2184C11.5571 2.99385 11.193 2.99385 10.9685 3.2184L7.50005 6.68682L4.03164 3.2184C3.80708 2.99385 3.44301 2.99385 3.21846 3.2184C2.99391 3.44295 2.99391 3.80702 3.21846 4.03157L6.68688 7.49999L3.21846 10.9684C2.99391 11.193 2.99391 11.557 3.21846 11.7816C3.44301 12.0061 3.80708 12.0061 4.03164 11.7816L7.50005 8.31316L10.9685 11.7816C11.193 12.0061 11.5571 12.0061 11.7816 11.7816C12.0062 11.557 12.0062 11.193 11.7816 10.9684L8.31322 7.49999L11.7816 4.03157Z"
                      fill="currentColor"
                      fillRule="evenodd"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
                <div className="flex px-3 py-3.5">
                  <div className="flex items-center text-xs text-white">
                    <div>{language}</div>
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 15 15"
                      fill="none"
                      className="-mt-0.5 ml-1 text-zinc-400"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.13523 6.15803C3.3241 5.95657 3.64052 5.94637 3.84197 6.13523L7.5 9.56464L11.158 6.13523C11.3595 5.94637 11.6759 5.95657 11.8648 6.15803C12.0536 6.35949 12.0434 6.67591 11.842 6.86477L7.84197 10.6148C7.64964 10.7951 7.35036 10.7951 7.15803 10.6148L3.15803 6.86477C2.95657 6.67591 2.94637 6.35949 3.13523 6.15803Z"
                        fill="currentColor"
                        fillRule="evenodd"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="ml-auto text-zinc-400"
                  >
                    <path
                      d="M1 9.50006C1 10.3285 1.67157 11.0001 2.5 11.0001H4L4 10.0001H2.5C2.22386 10.0001 2 9.7762 2 9.50006L2 2.50006C2 2.22392 2.22386 2.00006 2.5 2.00006L9.5 2.00006C9.77614 2.00006 10 2.22392 10 2.50006V4.00002H5.5C4.67158 4.00002 4 4.67159 4 5.50002V12.5C4 13.3284 4.67158 14 5.5 14H12.5C13.3284 14 14 13.3284 14 12.5V5.50002C14 4.67159 13.3284 4.00002 12.5 4.00002H11V2.50006C11 1.67163 10.3284 1.00006 9.5 1.00006H2.5C1.67157 1.00006 1 1.67163 1 2.50006V9.50006ZM5 5.50002C5 5.22388 5.22386 5.00002 5.5 5.00002H12.5C12.7761 5.00002 13 5.22388 13 5.50002V12.5C13 12.7762 12.7761 13 12.5 13H5.5C5.22386 13 5 12.7762 5 12.5V5.50002Z"
                      fill="currentColor"
                      fillRule="evenodd"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
                <div className="font-code whitespace-pre-wrap p-2.5 text-zinc-300">
                  <Typewriter
                    key={state === "clicked" ? language : "empty"}
                    onInit={handleTypewriterInit}
                    options={{
                      delay: 1,
                      // Fix incorrect type export (should be string[] instead of string).
                      stringSplitter: stringSplitter as unknown as () => string,
                    }}
                  />
                </div>
              </motion.div>
            </div>
            <div className="flex flex-col items-center justify-center space-y-5 sm:flex-row sm:items-start sm:space-x-5 sm:space-y-0">
              <div className="w-48">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.figma.com/community/plugin/1194222042313126344"
                >
                  <Button>Try the Figma plugin</Button>
                </a>
              </div>
              <div className="w-36">
                <button
                  type="button"
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={() => setVideo(true)}
                  className="flex w-full items-center rounded-lg bg-zinc-600 px-5 py-2 text-sm font-semibold text-white hover:opacity-95 active:opacity-90 disabled:opacity-50"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="mr-3 h-4 w-4 text-white"
                  >
                    <path d="M6.3 2.841A1.5 1.5 0 004 4.11V15.89a1.5 1.5 0 002.3 1.269l9.344-5.89a1.5 1.5 0 000-2.538L6.3 2.84z" />
                  </svg>
                  Play video
                </button>
              </div>
            </div>
            <div className="mt-2.5 hidden items-center justify-center text-center text-xs font-medium text-zinc-200 sm:flex">
              <svg
                width="15"
                height="12"
                viewBox="0 0 15 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="h-3"
              >
                <path
                  d="M14 11C5 11.5 5.5 5 4 2"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.5 5.5L4 1.5L8 3.5"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <div className="mt-2.5 pl-2">
                No account needed, free forever! ✨
              </div>
            </div>
            <div className="flex flex-wrap justify-center space-x-5 pt-12 text-sm font-medium text-zinc-400">
              <div className="my-1 py-0.5">Converts Figma designs into:</div>
              <button
                type="button"
                onClick={() => setLanguage("Only CSS")}
                className={`my-1 border-b py-0.5 ${
                  language === "Only CSS"
                    ? "text-white"
                    : "border-transparent hover:text-zinc-300"
                }`}
              >
                Only CSS
              </button>
              <button
                type="button"
                onClick={() => setLanguage("Only CSS-in-JS")}
                className={`my-1 border-b py-0.5 ${
                  language === "Only CSS-in-JS"
                    ? "text-white"
                    : "border-transparent hover:text-zinc-300"
                }`}
              >
                Only CSS-in-JS
              </button>
              <button
                type="button"
                onClick={() => setLanguage("Only Tailwind")}
                className={`my-1 border-b py-0.5 ${
                  language === "Only Tailwind"
                    ? "text-white"
                    : "border-transparent hover:text-zinc-300"
                }`}
              >
                Only Tailwind
              </button>
              <button
                type="button"
                onClick={() => setLanguage("HTML with CSS")}
                className={`my-1 border-b py-0.5 ${
                  language === "HTML with CSS"
                    ? "text-white"
                    : "border-transparent hover:text-zinc-300"
                }`}
              >
                HTML with CSS
              </button>
              <button
                type="button"
                onClick={() => setLanguage("HTML with Tailwind")}
                className={`my-1 border-b py-0.5 ${
                  language === "HTML with Tailwind"
                    ? "text-white"
                    : "border-transparent hover:text-zinc-300"
                }`}
              >
                HTML with Tailwind
              </button>
              <button
                type="button"
                onClick={() => setLanguage("JSX with CSS-in-JS")}
                className={`my-1 border-b py-0.5 ${
                  language === "JSX with CSS-in-JS"
                    ? "text-white"
                    : "border-transparent hover:text-zinc-300"
                }`}
              >
                JSX with CSS-in-JS
              </button>
              <button
                type="button"
                onClick={() => setLanguage("JSX with Tailwind")}
                className={`my-1 border-b py-0.5 ${
                  language === "JSX with Tailwind"
                    ? "text-white"
                    : "border-transparent hover:text-zinc-300"
                }`}
              >
                JSX with Tailwind
              </button>
            </div>
            <div className="mx-auto flex max-w-sm items-center justify-center space-x-2 pb-16 pt-12 text-sm text-sky-400 sm:max-w-none sm:pt-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="h-6 w-6 flex-shrink-0"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                />
              </svg>
              <div>
                Works with frameworks like Next and React (JSX), Vue and Svelte
                (HTML).
              </div>
            </div>
          </div>
          <motion.div
            variants={featureVariants1}
            className="-mx-7 space-y-12 border-t border-zinc-700 p-14 sm:flex sm:space-y-0"
          >
            <a
              href="https://www.producthunt.com/posts/frontender?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-frontender"
              target="_blank"
              rel="noreferrer"
              className="block flex-shrink-0 sm:mr-7"
            >
              {/* eslint-disable-next-line @next/next/no-img-element */}
              <img
                src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=383000&theme=dark"
                alt="Frontender - A&#0032;smart&#0032;Figma&#0032;plugin&#0032;that&#0032;writes&#0032;front&#0045;end&#0032;code&#0032;🪄 | Product Hunt"
                width="250"
                height="54"
              />
            </a>
            <Quote
              text="A game-changing tool for designers looking to streamline their
      workflow."
              name="Zheng Haibo"
              avatar="https://ph-avatars.imgix.net/3046947/d8e7dc8d-d744-4860-89ee-23e169d397ad?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=36&h=36&fit=crop&dpr=1"
              className=""
            />
            <Quote
              text="Being a front-end developer, I must say this plugin is the greatest I've ever seen."
              name="Mian Azan"
              className="sm:hidden lg:flex"
              avatar="https://ph-avatars.imgix.net/4140865/original?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=36&h=36&fit=crop&dpr=1"
            />
            <Quote
              text="Love this product - generates crazy accurate results!"
              name="Krish Nerkar"
              className="sm:hidden xl:flex"
              avatar="https://ph-avatars.imgix.net/3046947/d8e7dc8d-d744-4860-89ee-23e169d397ad?auto=compress&codec=mozjpeg&cs=strip&auto=format&w=36&h=36&fit=crop&dpr=1"
            />
          </motion.div>
          <motion.div
            variants={featureVariants1}
            className="-mx-7 grid-cols-2 border-t border-zinc-700 md:grid"
          >
            <Feature
              title="Your front-end assistant"
              src="/f1.svg"
              border
              end
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="mb-4 h-6 w-6 text-purple-300"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8.25 3v1.5M4.5 8.25H3m18 0h-1.5M4.5 12H3m18 0h-1.5m-15 3.75H3m18 0h-1.5M8.25 19.5V21M12 3v1.5m0 15V21m3.75-18v1.5m0 15V21m-9-1.5h10.5a2.25 2.25 0 002.25-2.25V6.75a2.25 2.25 0 00-2.25-2.25H6.75A2.25 2.25 0 004.5 6.75v10.5a2.25 2.25 0 002.25 2.25zm.75-12h9v9h-9v-9z"
                  />
                </svg>
              }
            >
              Select any layer in Figma, and Frontender will convert it to
              front-end code. It&apos;s like having a personal junior developer,
              always available.
            </Feature>
            <Feature
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="mb-4 h-6 w-6 text-emerald-300"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                  />
                </svg>
              }
              title="Works with any Figma file"
              src="/f2.svg"
            >
              Frontender does not depend on autolayout or well-organized layers.
              It figures out order, hierarchy and meaning &ndash; even for messy
              files.
            </Feature>
          </motion.div>
          <motion.div
            variants={featureVariants2}
            className="-mx-7 grid-cols-2 border-t border-zinc-700 md:grid"
          >
            <Feature
              title="Knows CSS & Tailwind inside and out"
              src="/f3.svg"
              border
              cap
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 54 33"
                  className="mb-4 h-6 w-6"
                >
                  <g clipPath="url(#prefix__clip0)">
                    <path
                      fill="#38bdf8"
                      fillRule="evenodd"
                      d="M27 0c-7.2 0-11.7 3.6-13.5 10.8 2.7-3.6 5.85-4.95 9.45-4.05 2.054.513 3.522 2.004 5.147 3.653C30.744 13.09 33.808 16.2 40.5 16.2c7.2 0 11.7-3.6 13.5-10.8-2.7 3.6-5.85 4.95-9.45 4.05-2.054-.513-3.522-2.004-5.147-3.653C36.756 3.11 33.692 0 27 0zM13.5 16.2C6.3 16.2 1.8 19.8 0 27c2.7-3.6 5.85-4.95 9.45-4.05 2.054.514 3.522 2.004 5.147 3.653C17.244 29.29 20.308 32.4 27 32.4c7.2 0 11.7-3.6 13.5-10.8-2.7 3.6-5.85 4.95-9.45 4.05-2.054-.513-3.522-2.004-5.147-3.653C23.256 19.31 20.192 16.2 13.5 16.2z"
                      clipRule="evenodd"
                    />
                  </g>
                  <defs>
                    <clipPath id="prefix__clip0">
                      <path fill="#fff" d="M0 0h54v32.4H0z" />
                    </clipPath>
                  </defs>
                </svg>
              }
            >
              Frontender understands CSS, but also Tailwind extremely well. It
              can use arbitrary values, or even find the closest value in the
              config.
            </Feature>
            <Feature
              title="Custom config support"
              src="/f4.svg"
              end
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="mb-4 h-6 w-6 text-amber-400"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.5 12a7.5 7.5 0 0015 0m-15 0a7.5 7.5 0 1115 0m-15 0H3m16.5 0H21m-1.5 0H12m-8.457 3.077l1.41-.513m14.095-5.13l1.41-.513M5.106 17.785l1.15-.964m11.49-9.642l1.149-.964M7.501 19.795l.75-1.3m7.5-12.99l.75-1.3m-6.063 16.658l.26-1.477m2.605-14.772l.26-1.477m0 17.726l-.26-1.477M10.698 4.614l-.26-1.477M16.5 19.794l-.75-1.299M7.5 4.205L12 12m6.894 5.785l-1.149-.964M6.256 7.178l-1.15-.964m15.352 8.864l-1.41-.513M4.954 9.435l-1.41-.514M12.002 12l-3.75 6.495"
                  />
                </svg>
              }
            >
              Do you work with a custom Tailwind config? Just paste it in
              Frontender, and watch how it writes production-ready code using
              your custom classes.
            </Feature>
          </motion.div>
          <div className="-mx-7 border-t border-zinc-700">
            <div className="flex flex-col items-center pb-28 pt-20">
              <div className="text-2xl font-semibold text-zinc-200">
                Try it right now in Figma
              </div>
              <p className="mb-6 mt-3 text-center text-zinc-400">
                You can use Frontender for free, forever.
                <br />
                15 conversions are on us, every month.
              </p>
              <div className="w-48">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.figma.com/community/plugin/1194222042313126344"
                >
                  <Button>Try the Figma plugin &rarr;</Button>
                </a>
              </div>
              <div className="mt-8 flex items-center space-x-2 text-sm text-zinc-400">
                <div>✨</div>
                <div>No account needed, 15 free conversions each month.</div>
              </div>
            </div>
          </div>
          <Footer
            acceptedCookies={props.acceptedCookies}
            setAcceptedCookies={props.setAcceptedCookies}
          />
        </div>
      </motion.div>
    </>
  )
}
